import React, { useContext, useEffect } from "react";
import { AppContext } from "./App";
import axios from "axios";
import { RiDeleteBinLine } from "react-icons/ri"; // Delete icon
import { useCart } from "./components/CartProvider";

export const Cart = () => {
  const { cartItems, clearCart, deleteItem } = useCart();
  const { url } = useContext(AppContext);

  const loggedUser = localStorage.getItem("logged");

  const totalPrice = () => {
    const sum = cartItems.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.price * currentValue.quantity;
    }, 0);

    return sum.toFixed(2);
  };

  const handleCheckout = async () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;

    try {
      const response = await axios.post(`${url}create-checkout-session`, {
        userId: loggedUser,
        cartItems,
        formattedTime,
      });

      window.location = response.data.url;
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      {cartItems.length > 0 ? (
        <>
          <h1 className="text-2xl font-semibold">My Cart ({cartItems.length})</h1>
          <button
            onClick={() => clearCart(loggedUser)}
            className="text-red-400 text-sm"
          >
            Clear cart
          </button>
          <div className="p-4 flex flex-col lg:flex-row lg:space-x-6">
            <div className="w-full">
              {cartItems.map((item) => (
                <div
                key={item._id}
                className="flex flex-col md:flex-row md:space-x-4 w-full mb-4 relative"
                >
                  <img
                    className="w-full md:w-80 object-contain rounded"
                    src={item.imageUrl}
                    alt=""
                  />
                  <div>
                    <h1 className="font-semibold text-2xl">{item.name}</h1>
                    <p className="text-xl">${item.price}</p>
                  </div>
                  <RiDeleteBinLine
                    className="bg-white dark:text-black rounded p-2 text-3xl cursor-pointer absolute top-4 right-4"
                    onClick={() => deleteItem(item._id)}
                  />
                </div>
              ))}
            </div>
            <div className="bg-gray-100 dark:bg-white dark:text-black p-4 rounded h-fit w-full lg:w-1/2">
              <div className="flex items-center justify-between text-gray-400">
                <h1>Subtotal:</h1>
                <p className="font-bold">${totalPrice()}</p>
              </div>
              <div className="flex py-2 items-center justify-between text-gray-400">
                <h1>Discount:</h1>
                <p className="font-bold">$0</p>
              </div>
              <div className="flex pb-4 items-center justify-between">
                <h1>Total:</h1>
                <p className="font-bold">${totalPrice()}</p>
              </div>
              <button
                onClick={handleCheckout}
                className={
                  cartItems.length < 1
                    ? "bg-gray-500 px-5 py-2.5 text-white pointer-events-none w-full"
                    : "bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded w-full"
                }
              >
                Checkout with Stripe
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center h-full">
          <h1 className="text-3xl font-bold py-4 text-gray-600">
            Your cart is empty
          </h1>
          <p className="text-gray-400">
            You have no items in your shopping cart.
          </p>
          <p className="text-gray-400 pb-6">Let's go buy something!</p>
          <a href="/" className="bg-black rounded-full py-2.5 px-5 text-white">
            Back to Shop
          </a>
        </div>
      )}
    </div>
  );
};
